<!--
 * @Author: your name
 * @Date: 2021-04-09 15:31:55
 * @LastEditTime: 2021-05-07 19:00:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\integral\index.vue
-->
<template>
  <div class="allclassmsg">
    <navigation />
    <div class="msgclass">
      <div>点亮专属勋章</div>
      <div>感谢您的辛勤付出，让职场公平更进一步！</div>
    </div>
    <div class="xunzhnag">
      <div></div>
      <div @click="lightup('3')">
        <img
          class="xun1_2"
          v-if="usable_point > '35000000' || usable_point == '35000000'"
          src="../../assets/xun1.png"
          alt=""
        />
        <img class="xun1_2" v-else src="../../assets/xun1_1.png" alt="" />
        <div class="xun2">点石成金 超级猎手</div>
        <div
          class="xun3"
          v-if="usable_point > '35000000' || usable_point == '35000000'"
        >
          可点亮
        </div>
        <div class="xun4" v-else>不可点亮</div>
      </div>
      <div></div>
    </div>
    <div class="xunzhnag">
      <div @click="lightup('0')">
        <img
          class="xun1"
          v-if="usable_point > '5000' || usable_point == '5000'"
          src="../../assets/xun2.png"
          alt=""
        />
        <img class="xun1" v-else src="../../assets/xun2_2.png" alt="" />
        <div class="xun2">不拘一格</div>
        <div
          class="xun3"
          v-if="usable_point > '5000' || usable_point == '5000'"
        >
          可点亮
        </div>
        <div class="xun4" v-else>不可点亮</div>
      </div>
      <div @click="lightup('1')">
        <img
          class="xun1"
          v-if="usable_point > '10000' || usable_point == '10000'"
          src="../../assets/xun3.png"
          alt=""
        />
        <img class="xun1" v-else src="../../assets/xun3_3.png" alt="" />
        <div class="xun2">足智多谋</div>
        <div
          class="xun3"
          v-if="usable_point > '10000' || usable_point == '10000'"
        >
          可点亮
        </div>
        <div class="xun4" v-else>不可点亮</div>
      </div>
      <div @click="lightup('2')">
        <img
          class="xun1"
          v-if="usable_point > '30000' || usable_point == '30000'"
          src="../../assets/xun4.png"
          alt=""
        />
        <img class="xun1" v-else src="../../assets/xun4_4.png" alt="" />
        <div class="xun2">火眼金睛</div>
        <div
          class="xun3"
          v-if="usable_point > '30000' || usable_point == '30000'"
        >
          可点亮
        </div>
        <div class="xun4" v-else>不可点亮</div>
      </div>
    </div>

    <div class="nomsg"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      noborder: "noborder",
      color: "white",
      usable_point: "",
    };
  },
  created() {
    this.getjifenmsg();
  },
  methods: {
    getjifenmsg() {
      this.$http
        .post("/firm/v1/Userinfo/usablePoint", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.usable_point = JSON.parse(res.data).data.usable_point;
        });
    },
    lightup(msg) {
      this.$router.push({
        path: "/integralok",
        query: {
          lightupmsg: msg,
        },
      });
    },
  },
  setup() {},
};
</script>
<style scoped>
.visi {
  visibility: hidden;
}
.nomsg {
  height: 0.3rem;
}
.xun1 {
  width: 1.8rem;
  height: 2.3rem;
  border-radius: 0.16rem;
}
.xun1_2 {
  width: 2.8rem;
  height: 3.58rem;
  border-radius: 0.16rem;
}
.xun2 {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;
  margin-top: 0.36rem;
  margin-bottom: 0.2rem;
}
.xun4 {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.28rem;
}
.xun3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55613;
  line-height: 0.28rem;
}
.xunzhnag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.32rem;
  margin-top: 0.94rem;
  margin-bottom: 0.94rem;
}
.xunzhnag > div {
  text-align: center;
}
.msgclass {
  text-align: center;
  margin-top: 0.8rem;
}
.msgclass > div:first-child {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.6rem;
}
.msgclass > div:nth-child(2) {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.3rem;
  margin-top: 0.22rem;
}
.allclassmsg {
  min-height: 100%;
  background-color: white;
}
.allmsgclass >>> .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
</style>
